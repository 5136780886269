/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Drawer, DatePicker } from 'antd';
import BoxSearchFlignhts from '@/componentWebs/vietnamTickets/BoxSearchFlignhts';
import { getResponsiveImage } from '@/componentWebs/NbmImageNew';
import moment from 'moment';
import cookie from 'js-cookie';
import EncodeUrl from '@/utils/encode';
import { stringify } from 'qs';
import router from 'next/router';

// import $ from 'jquery';
// import cookie from 'js-cookie';
// import publicRuntimeConfig from '@/utils/config';
import publicRuntimeConfig from '@/utils/config';

const weekday = ['CN', 'Thứ 2', 'Thứ 3', 'Thứ 4', 'Thứ 5', 'Thứ 6', 'Thứ 7'];

@connect(({ webs }) => ({
  webs,
}))
class Header extends Component {
  static async getInitialProps(ctx) {
    const { query } = ctx;
    return {
      query,
    };
  }

  state = {
    visible: false,
    visibleSearch: false,
    visibleCalender: false,
    hideNav: null,
    saveHeader: this.props.webs.saveHeader,
    key: Math.random(),
    dateStart: moment(),
    dateEnd: moment(),
    pathname: '',
  };

  componentDidMount() {
    const item = (router && router.router && router.router.pathname) || '';
    this.setState({
      pathname: item,
      hideNav: window.innerWidth < 1024,
    });
  }

  componentWillReceiveProps(nextProps) {
    const {
      webs: { saveHeader },
    } = this.props;
    if (saveHeader !== nextProps.webs.saveHeader) {
      this.setState({
        saveHeader: nextProps.webs.saveHeader,
        key: Math.random(),
      });
    }
  }

  onChangMenu = () => {
    const { visible } = this.state;
    this.setState({ visible: !visible });
  };

  disabledDate = current => {
    const { dateStart } = this.state;
    // Can not select days before today and today
    const data =
      (cookie.get('dateStart') && moment(cookie.get('dateStart'))) || dateStart || moment();
    const clone = _.cloneDeep(data);
    return current && current < clone;
  };

  disableReceive = current => {
    // Can not select days before today and today
    const data = moment();
    const clone = _.cloneDeep(data);
    return current && current < clone.subtract(1, 'days');
  };

  onSubmitCalender = () => {
    const { dateStart, dateEnd, saveHeader, visibleCalender } = this.state;
    const { dispatch } = this.props;
    cookie.set('dateStart', dateStart);
    cookie.set('dateEnd', dateEnd);
    dispatch({
      type: 'webs/headerText',
      payload: {
        name: `${(saveHeader && saveHeader.name) || ''}`,
        date: [dateStart, dateEnd],
        isDetailKS: true,
      },
    });
    this.setState({ visibleCalender: !visibleCalender });
  };

  getDateOfWeek = date => {
    const nameDay = `${weekday[moment(date).day()]}, ${moment(date).format('DD/MM/YYYY')}`;
    return nameDay;
  };

  render() {
    const {
      menuHeader,
      menuLeft,
      asPath,
      dataSite,
      query,
      webs: { visaCall },
    } = this.props;
    const {
      visible,
      visibleSearch,
      hideNav,
      saveHeader,
      key,
      visibleCalender,
      dateStart,
      dateEnd,
      pathname,
    } = this.state;
    // console.log("visaCall: ", visaCall);
    // console.log("item: ", pathname, ( pathname && pathname.indexOf("/index")) !== -1);

    const mobile =
      (dataSite.siteProfiles && dataSite.siteProfiles.hotline) ||
      (dataSite.places && dataSite.places.mobile);
    const email =
      (dataSite.siteProfiles && dataSite.siteProfiles.email) ||
      (dataSite.places && dataSite.places.email);
    const checkShowMenuLeft =
      menuHeader &&
      menuHeader.length > 0 &&
      menuHeader.findIndex(item => `/${item.urlSlugs}` === asPath);
    const view1 = (
      <div
        className="nav-icon-mobile flex-a-c hidden-pc"
        style={{
          padding: 5,
          backgroundColor: '#FEF5F8',
          borderRadius: '50%',
          display: !saveHeader.isDetailKS && 'none',
          // opacity: saveHeader.isDetailKS === undefined && 0
        }}
      >
        <img
          src="/static/vietnamTickets/images/header-calender.svg"
          className="lazyload"
          alt="search"
          onClick={() =>
            this.setState({
              visibleCalender: !visibleCalender,
            })
          }
        />
      </div>
    );
    const view2 = (
      <div
        className="nav-icon-mobile flex-a-c hidden-pc"
        style={{
          display: saveHeader.isDetailKS && 'none',
          // opacity: saveHeader.isDetailKS === undefined && 0
        }}
      >
        <img
          src="/static/vietnamTickets/images/search.svg"
          className="lazyload"
          alt="search"
          onClick={() =>
            this.setState({
              visibleSearch: !visibleSearch,
            })
          }
        />
      </div>
    );

    // console.log(asPath, checkShowMenuLeft);
    return (
      <React.Fragment>
        <div className="nav-hotline-header">
          <div
            className={`${
              (checkShowMenuLeft && checkShowMenuLeft > 2) ||
              (checkShowMenuLeft === -1 && asPath !== '/')
                ? 'container-normal'
                : 'container'
            } nav-hotline-header_main`}
          >
             <span
              className="nav-hotline-header-span"
              onClick={() => (window.location.href = `mailto:${email}`)}
            >
              <i className="fas fa-envelope" />
              <span className="hidden-mobile">Email:</span> {email}
            </span>
            <span
              className="nav-hotline-header-span"
              onClick={() => (window.location.href = `tel:${mobile}`)}
            >
              <i className="fas fa-phone-alt" />
              <span className="hidden-mobile">Hotline toàn quốc:</span> {mobile}
            </span>
           
          </div>
        </div>
        <div className="nav-header" key={key}>
          <div
            className={`${
              (checkShowMenuLeft && checkShowMenuLeft > 2) ||
              (checkShowMenuLeft === -1 && asPath !== '/')
                ? 'container-normal'
                : 'container'
            }`}
          >
            <div className="nav-header__wrapper">
              <div className="nav-icon-mobile flex-a-c hidden-pc">
                <img
                  src="/static/vietnamTickets/images/menuNav.svg"
                  className="lazyload"
                  alt="menu"
                  onClick={this.onChangMenu}
                />
              </div>
              {!hideNav ? (
                <a href="/" className="nav-header__img flex">
                  <img
                    width="auto"
                    height="auto"
                    className="lazyload"
                    src={`${publicRuntimeConfig.IMAGE_SERVER_NEW}${
                      publicRuntimeConfig.IMAGE_PROJECT
                    }${dataSite && dataSite.logo && dataSite.logo[0] && dataSite.logo[0].file}`}
                    alt="Logo"
                  />
                </a>
              ) : (
                <>
                  {Object.keys(saveHeader) && Object.keys(saveHeader).length > 0 ? (
                    <div className="text-Nav flex-a-c">
                      <div className="title">{saveHeader.name}</div>
                      {saveHeader.date && (
                        <div className="date">
                          {`${saveHeader.date[0] &&
                            moment(saveHeader.date[0]).format('DD/MM/YYYY')} 
                           ${saveHeader.date[1] ? '-' : ''} 
                           ${
                             saveHeader.date[1]
                               ? moment(saveHeader.date[1]).format('DD/MM/YYYY')
                               : ''
                           }
                           `}
                        </div>
                      )}
                    </div>
                  ) : (
                    <a href="/" className="nav-header__img flex">
                      <img
                        className="lazyload"
                        src={`${publicRuntimeConfig.IMAGE_SERVER_NEW}${
                          publicRuntimeConfig.IMAGE_PROJECT
                        }${dataSite && dataSite.logo && dataSite.logo[0] && dataSite.logo[0].file}`}
                        width="auto"
                        height="auto"
                        alt="Logo"
                      />
                    </a>
                  )}
                </>
              )}
              {view1}
              {view2}
              <ul className="nav-header__list flex hidden-m-t" style={{ marginBottom: 0 }}>
                {menuHeader &&
                  menuHeader.length > 0 &&
                  menuHeader.map((item, index) => (
                    <li key={item.id}>
                      <a href={`/${item.urlSlugs}`}>
                        {index === 2 && (
                          <i className="fas fa-heart" style={{ marginRight: '5px' }} />
                        )}
                        {item.name}
                      </a>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>

        {/* ${(
            (checkShowMenuLeft && checkShowMenuLeft > 2) ||
            (checkShowMenuLeft === -1 && asPath !== '/')) &&
            'hidden-pc'
          }  */}
        <div
          className={`nav-sticky 
            ${((checkShowMenuLeft && checkShowMenuLeft > 2) ||
              (checkShowMenuLeft === -1 && asPath !== '/')) &&
              'nav-sticky-single'}
            `}
        >
          <ul className="nav-sticky__list">
            {menuLeft &&
              menuLeft.length > 0 &&
              menuLeft.map(item => (
                <li
                  className={`nav-sticky__item ${((checkShowMenuLeft && checkShowMenuLeft > 2) ||
                    (checkShowMenuLeft === -1 && asPath !== '/')) &&
                    'nav-sticky-single-item'}`}
                  key={item.id}
                >
                  <a href={`/${item.urlSlugs}`}>
                    <img
                      className="lazyload"
                      src={`/static/vietnamTickets/images/${item.icon}`}
                      alt={item.name}
                      width="100"
                      height="100"
                      className="borderShaw"
                    />
                    <span className="text hidden-pc">{item.name}</span>
                  </a>
                </li>
              ))}
            <li
              className="form-call-wrapper form-call-mobile hidden-m-t"
              onClick={() => (window.location.href = `tel:${mobile}`)}
            >
              <div className="form-call-btn">
                {/* <i className="form-call-phone-icon" /> */}
                <i className="fas fa-phone-alt" />
              </div>
            </li>
            {/* <li className="nav-sticky__item hidden-m-t">
              <a href="#">
                <img src="/static/vietnamTickets/images/zalo.png" alt="" />
              </a>
            </li>
            <li className="nav-sticky__item hidden-m-t">
              <a href="#">
                <img src="/static/vietnamTickets/images/mess.png" alt="" />
              </a>
            </li> */}
          </ul>
        </div>
        <div
          className={
            saveHeader && saveHeader.isDetail
              ? 'form-call-wrapper form-call-mobile_inMobile detailCall  hidden-pc'
              : 'form-call-wrapper form-call-mobile_inMobile  hidden-pc'
          }
          onClick={() => (window.location.href = `tel:${mobile}`)}
        >
          <div className="form-call-btn">
            <i className="fas fa-phone-alt" />
          </div>
        </div>
        <Drawer
          visible={visible}
          // closable={false}
          width="100%"
          placement="left"
          onClose={this.onChangMenu}
          bodyStyle={{ padding: '18px' }}
        >
          <ul className="menu_nav_mobile" style={{ marginBottom: 0 }}>
            <li key="home">
              <img src="/static/vietnamTickets/images/home.svg" alt="home" className="lazyload" />
              <a href="/">Trang chủ</a>
            </li>
            {menuHeader &&
              menuHeader.length > 0 &&
              menuHeader.map(item => (
                <li key={item.id}>
                  <img
                    src={`/static/vietnamTickets/images/${item.icon}`}
                    alt={item.name}
                    className="lazyload"
                  />
                  <a href={`/${item.urlSlugs}`}>{item.name}</a>
                </li>
              ))}
            {/* <li key='suport'>
              <img src='/static/vietnamTickets/images/contact.svg' alt='suport' /><a href='#'>Hỗ trợ khách hàng 24/7</a>
            </li> */}
            {dataSite && dataSite.siteProfiles && dataSite.siteProfiles.address && (
              <li key="address">
                <a>Địa chỉ: {dataSite && dataSite.siteProfiles && dataSite.siteProfiles.address}</a>
              </li>
            )}
          </ul>
          <div />
        </Drawer>

        <Drawer
          visible={visibleSearch}
          // closable={false}
          width="100%"
          className="filter-mobile"
          placement="right"
          onClose={() =>
            this.setState({
              visibleSearch: !visibleSearch,
            })
          }
          bodyStyle={{ padding: '18px' }}
        >
          <BoxSearchFlignhts typeTab="all" />
          <div />
        </Drawer>

        <Drawer
          visible={visibleCalender}
          // closable={false}
          width="100%"
          placement="right"
          onClose={() =>
            this.setState({
              visibleCalender: !visibleCalender,
            })
          }
          bodyStyle={{ padding: '18px' }}
        >
          <h2 style={{ textAlign: 'center', fontSize: 16, fontWeight: 'bold' }}>Chọn lại ngày</h2>
          <div
            className="banner-main__box-formItem"
            style={{ alignItems: 'flex-start', paddingTop: 25 }}
          >
            <div className="banner-main__input banner-main__select">
              <span className="label">Ngày nhận phòng</span>

              <DatePicker
                placeholder="Ngày nhận phòng"
                disabledDate={this.disableReceive}
                inputReadOnly
                format="DD/MM/YYYY"
                className="dateFiler"
                allowClear={false}
                value={(cookie.get('dateStart') && moment(cookie.get('dateStart'))) || dateStart}
                onChange={value => {
                  this.setState({ dateStart: value });
                  // setDateDen(value)
                  // cookie.set('dateStart', value)
                  if (
                    value - ((cookie.get('dateEnd') && moment(cookie.get('dateEnd'))) || dateEnd) >
                    0
                  ) {
                    // setDateDi(value)
                    this.setState({ dateEnd: value });
                    // cookie.set('dateEnd', value)
                  }
                }}
              />
            </div>
            <div className="banner-main__input search-rigth">
              <span className="label">Ngày trả phòng</span>

              <DatePicker
                placeholder="Ngày trả phòng"
                disabledDate={this.disabledDate}
                format="DD/MM/YYYY"
                className="dateFiler"
                inputReadOnly
                allowClear={false}
                value={(cookie.get('dateEnd') && moment(cookie.get('dateEnd'))) || dateEnd}
                onChange={value => {
                  this.setState({ dateEnd: value });
                }}
                // value={moment(state.DepartDate2)}
              />
            </div>
          </div>
          <button
            className="btn btn--filter flex-a-c hidden-pc"
            onClick={this.onSubmitCalender}
            type="button"
          >
            <span>Cập nhật</span>
          </button>
        </Drawer>
      </React.Fragment>
    );
  }
}
export default Header;
